import React from "react";

const Ourschool = () => {
  return (
    <>
      <div className="our-school">
        <div className="our-row">
          <div className="our-col">
            <h1>About Us</h1>
            <p>
            Sanskar Bharti Vidyapeeth was established a decade ago is located in the city of Lakes, Bhopal, in Madhya Pradesh. Sanskar Bharti Vidyapeeth has been established by the Sanskar Bharti Foundation, a registered, not-for-profit organization which professionally manages the school. Sanskar Bharti Vidyapeeth, with beautiful and pleasant landscapes in and around the premises of the school, surrounded with lush greenery all over, and is equipped with environment and child-friendly architecture, which is age appropriate and suitable to the needs of the child. The school strives to be the best school in area and with this thought in mind, Sanskar Bharti Vidyapeeth focuses on the all round development of the child in academics, activities and sports. Equal and fair importance is given not only to academics but also to activities and sports, thus ensuring that the child is able to decide and build its own future. Upholding strong values, morals, principles, ideologies and ethics, the school’s programmes are designed to develop the inquiring and inquisitive mind for the Sciences, the passion and heart for the Arts and the zeal, enthusiasm and physique for Sports. The curricular and extra-curricular activities in Sanskar Bharti Vidyapeeth seek to instill self-confidence, responsibility, sensitivity and independence in children.The focus of the school being overall development of the child makes Sanskar Bharti Vidyapeeth the right place for children.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ourschool;
