import React from "react";
import { useEffect,useState } from "react";

const Facilities = () => {
  const [slides, setSlides] = useState([]);

  useEffect(() => {
    fetch('https://cms.maitretech.com/sanskar-bharti-vidyapeeth/items/facilities?fields=*.*.*')
      .then(response => response.json())
      .then(data => {
        // Assuming the slides array is in the data property
        const apiSlides = data.data || [];
        console.table(apiSlides)
        // Set the slides state
        setSlides(apiSlides);
      })
      .catch(error => console.error('Error:', error));
  }, []);
  return (
    <>
      <div className="container-fluid p-0">
        <div className="container liscontainer">
          <div className="facilitycontent">
            {/* swimming */}
            {
              slides.map((slide, index)=>(
                <div className="facilitCard" key={index} style={{overflow:'hidden'}}>
                <div className="facilimages">
                  <img src={slide?.image?.data?.full_url} alt="" />
                </div>
                <div className="facontent">
                  <div className="factitle">{slide.title}</div>
                  <p style={{padding:"10px"}}>
                    {slide.description}
                    </p>
                </div>
              </div>
              ))
            }
          </div>
        </div>
      </div>
    </>
  );
};

export default Facilities;
