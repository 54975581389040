import React from "react";
import { FaInstagram,FaFacebook,FaTwitter} from 'react-icons/fa'

const Footer = () => {
  return (
    <>
      <div class="main-footer">
        <div className="footer-top">
          <div className="footerlogo-img">
            <img src="./newLogo-removebg-preview.png" alt="" />
          </div>
          <div className="footer-content">
            <p>Have any questions?</p>
            <p>
              Call Us: 9303127859
            </p>
            <p>
              Call Us: +917553526449 , Mail Us:
              sanskarbhartividhyapeethschool@gmail.com
            </p>
            <p>
              Address - 78, Katara Hills Rd, Bagsewaniya, Bagmugaliya, Bhopal, Madhya Pradesh 
            </p>
          </div>
          <div className="socialmedia-link">
            <div className="socialall">
              <a
                href=""
                target="_blank"
              >
                <div className="alllink-soc">
                  <FaFacebook />
                </div>
              </a>
              <a
                href="/"
                target="_blank"
              >
                {" "}
                <div className="alllink-soc">
                  <FaTwitter />
                </div>
              </a>
              <a
                href=""
                target="_blank"
              >
                {" "}
                <div className="alllink-soc">
                  <FaInstagram />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <p>© All Rights Reserved by,Sanskar Bharti Vidyapeeth School</p>
          <p className="footer-made">
            Made with <i class=" ri-heart-3-fill"></i> by{" "}
            <a href="https://maitretech.com/"> Maitretech Solution</a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
