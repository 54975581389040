import React from "react";

const Contactus = () => {
  return (
    <>
      <div className="container-fluid p-4">
        <div className="container-fluid d-flex latest_card_box_ad p-0">
          <div className="contactleft map text-center">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3667.491538967826!2d77.48556367438921!3d23.188750710108156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x397c4141e52a3ab9%3A0x60750f0dfb16a313!2sSanskar%20Bharti%20Vidhyapeeth!5e0!3m2!1sen!2sin!4v1709717316522!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: "0" }}
              allowfullscreen=""
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div className="contactright text-left">
            <div className="titlecontact">
              <h4 className="ssss">School</h4>
              <hr />
              
              <p className="linkwrap">
                <b className="bbb">Mobile Number:</b>{" "}
                <a className="linkwrap mnc" href="tel:  9303127859">
                9303127859
                </a>{" "}
              </p>
              <p className="linkwrap">
                <b className="bbb">Phone:</b>{" "}
                <a className="linkwrap mnc" href="tel:  +917553526449">
                +917553526449
                </a>{" "}
              </p>
              <p className="linkwrap">
                <b className="bbb">Email:</b>{" "}
                <a
                  className="linkwrap mnc"
                  href="mailto: sanskarbhartividhyapeethschool@gmail.com "
                >
                  sanskarbhartividhyapeethschool@gmail.com
                </a>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
